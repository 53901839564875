<template>
    <div id="bannerCarousel" class="carousel slide carousel-fade mb-0">
        <!-- Indicatori pentru navigare -->
        <div class="carousel-indicators">
            <button v-for="(slide, index) in slides" :key="index" @click="setActiveSlide(index)"
                :class="{ active: index === activeSlide }" aria-current="index === activeSlide ? 'true' : 'false'"
                :aria-label="`Slide ${index + 1}`"></button>
        </div>

        <!-- Slide-urile caruselului -->
        <div class="carousel-inner">
            <div v-for="(slide, index) in slides" :key="index"
                :class="['carousel-item', { active: index === activeSlide }]">
                <div class="carousel-caption" :class="slide.align">
                    <h3 class="display-2 fw-bold animate fadeInDown" v-if="index === activeSlide">{{ slide.title }}</h3>
                    <div class="lead animate fadeIn two mb-3" v-if="index === activeSlide">{{ slide.description }}</div>
                    <div v-if="index === activeSlide">
                        <a v-if="slide.link" :href="slide.link" class="btn blue-btn animate fadeInUp one">DESCHIDE</a>
                        <router-link v-if="slide.route" :to="slide.route"
                            class="btn blue-btn animate fadeInUp one">DESCHIDE</router-link>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    data() {
        return {
            activeSlide: 0,
            intervalId: null, // Pentru stocarea ID-ului intervalului
            slides: [
                {
                    title: 'Facebook',
                    description: 'Uniunea Naţională a Executorilor Judecătoreşti din România',
                    link: 'https://www.facebook.com/unejromania',
                    route: '',
                    align: 'text-start',
                },
                {
                    title: 'SiLex',
                    description:
                        'Aplicația informatică SiLex are ca scop digitalizarea și standardizarea activității de executare silită.',
                    link: 'https://silex.executori.ro',
                    route: '',
                    align: 'text-end',
                },
                {
                    title: 'Harta executorilor',
                    description:
                        'Este o secțiune ușor de folosit care vă ajută la căutarea rapidă a executorilor din proximitatea dumneavoastră.',
                    link: '',
                    route: '/harta-executorilor',
                    align: 'text-start',
                },
                {
                    title: 'Tabloul executorilor',
                    description:
                        'Tabloul executorilor judecătoreşti din România',
                    link: '',
                    route: '/tabloul-executorilor',
                    align: 'text-end',
                },
            ],
        };
    },
    methods: {
        setActiveSlide(index) {
            this.activeSlide = index;
        },
        nextSlide() {
            this.activeSlide = (this.activeSlide + 1) % this.slides.length;
        },
    },
    mounted() {
        // Setăm un interval pentru schimbarea slide-urilor la fiecare 5 secunde
        this.intervalId = setInterval(() => {
            this.nextSlide();
        }, 5000); // 5000ms = 5 secunde
    },
    beforeUnmount() {
        // Oprim intervalul când componenta este distrusă
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    },
};

</script>

<style scoped>
.carousel {
    padding: 40px;
    height: 750px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: var(--shadow-header), url(../assets/img/ro2_blur.jpg);
    background-size: cover;
    /* Asigură că imaginea acoperă tot ecranul */
    background-position: center;
    /* Centrează imaginea */
    background-repeat: no-repeat;

}

@media (max-width: 576px) {
    .carousel {
        padding: 0;
        height: 500px;
    }
}

@media (max-width: 768px) {
    .carousel {
        margin: 0;
        padding: 0;
        height: 500px;

    }

    .carousel-caption h3 {
        font-size: 2rem;
        font-weight: bold;
    }

    .carousel-caption div {
        font-size: 1.2rem;
    }
}

.carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
}

.carousel-caption {
    color: white;
}




/* Indicatorii caruselului */
.carousel-indicators {
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: center;
}

.carousel-indicators button {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: white;
    border: 2px solid transparent;
    opacity: 0.5;
    margin: 0 5px;
    cursor: pointer;
}

.carousel-indicators button.active {
    opacity: 1;
    background-color: var(--primary-color);
}

.carousel-indicators button:hover {
    opacity: 1;
}
</style>