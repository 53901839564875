import { createRouter, createWebHistory } from 'vue-router'
import { useHead } from '@vueuse/head';
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Uniunea Naţională a Executorilor Judecătoreşti | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  // newsletter
  {
    path: "/newsletter",
    name: "Newsletter",
    component: () => import("../components/PageNewsletters.vue"),
    meta: {
      title: 'Newsletter | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Newsletter | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },

  // contact
  {
    path: "/contact", name: "Contact", component: () => import("../views/Contact.vue"),
    meta: {
      title: 'Contact | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Contact | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },

  // GDPR
  {
    path: "/GDPR", name: "Consimțământ privind prelucrarea datelor cu caracter personal", component: () => import("../views/GDPR.vue"),
    meta: {
      title: 'Consimțământ privind prelucrarea datelor cu caracter personal | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Consimțământ privind prelucrarea datelor cu caracter personal | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },

  // informatii generale
  {
    path: "/informatii-generale/biroul-de-presa", name: "Biroul de presă", component: () => import("../views/generale/biroul-de-presa/BiroulPresa.vue"),
    meta: {
      title: 'Biroul de presă | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Biroul de presă | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/informatii-generale/buletinul-informativ", name: "Buletinul informativ", component: () => import("../views/generale/biroul-de-presa/Buletinul.vue"),
    meta: {
      title: 'Buletinul informativ | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Buletinul informativ | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },

  {
    path: "/informatii-generale/despre", name: "Despre", component: () => import("../views/generale/prezentare/Despre.vue"),
    meta: {
      title: 'Despre | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Despre | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/informatii-generale/atributii-executor", name: "Atribuții executor", component: () => import("../views/generale/prezentare/AtributiiExecutor.vue"),
    meta: {
      title: 'Atribuții executor | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Atribuții executor | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/informatii-generale/despre-executarea-silita", name: "Despre executarea silită", component: () => import("../views/generale/prezentare/DespreExecutareaSilita.vue"),
    meta: {
      title: 'Despre executarea silită | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Despre executarea silită | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },

  {
    path: "/informatii-generale/admitere", name: "Cum să devin executor", component: () => import("../views/generale/admitere-in-profesie/Admitere.vue"),
    meta: {
      title: 'Cum să devin executor | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Cum să devin executor | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/informatii-generale/examene", name: "Examene și concursuri", component: () => import("../views/generale/admitere-in-profesie/Examene.vue"),
    meta: {
      title: 'Examene și concursuri | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Examene și concursuri | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/informatii-generale/arhiva-examene", name: "Arhivă examene și concursuri", component: () => import("../views/generale/admitere-in-profesie/ArhivaExamene.vue"),
    meta: {
      title: 'Arhivă examene și concursuri | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Arhivă examene și concursuri | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },

  { path: "/informatii-generale/admitere/examene/2021/sediu", name: "testtt", component: () => import("../views/generale/admitere-in-profesie/Sediu2021.vue") },
  {
    path: "/poca2018-2021", name: "POCA 2018-2021", component: () => import("../views/generale/proiecte/Poca2018-2021.vue"),
    meta: {
      title: 'POCA 2018-2021 | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'POCA 2018-2021 | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  { path: "/proiecte/poca/newsletter01", name: "newsletter01", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter1.vue") },
  { path: "/proiecte/poca/newsletter02", name: "newsletter02", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter2.vue") },
  { path: "/proiecte/poca/newsletter03", name: "newsletter03", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter3.vue") },
  { path: "/proiecte/poca/newsletter04", name: "newsletter04", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter4.vue") },
  { path: "/proiecte/poca/newsletter05", name: "newsletter05", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter5.vue") },
  { path: "/proiecte/poca/newsletter06", name: "newsletter06", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter6.vue") },
  { path: "/proiecte/poca/newsletter07", name: "newsletter07", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter7.vue") },
  { path: "/proiecte/poca/newsletter08", name: "newsletter08", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter8.vue") },
  { path: "/proiecte/poca/newsletter09", name: "newsletter09", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter9.vue") },
  { path: "/proiecte/poca/newsletter10", name: "newsletter10", component: () => import("../views/generale/proiecte/poca2018-2021/newsletter10.vue") },
  { path: "/proiecte/poca/inchidere", name: "inchidere", component: () => import("../views/generale/proiecte/poca2018-2021/inchidere.vue") },
  { path: "/proiecte/poca/activitati", name: "activitati", component: () => import("../views/generale/proiecte/poca2018-2021/activitati.vue") },
  { path: "/proiecte/poca/instruire", name: "instruire", component: () => import("../views/generale/proiecte/poca2018-2021/instruire.vue") },
  {
    path: "/poca2022-2023", name: "POCA 2022-2023", component: () => import("../views/generale/proiecte/Poca2022-2023.vue"),
    meta: {
      title: 'POCA 2022-2023 | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'POCA 2022-2023 | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  { path: "/poca2022-2023/cursuri", name: "cursuri", component: () => import("../views/generale/proiecte/Cursuri.vue") },

  {
    path: "/informatii-generale/protocoale", name: "Protocoale", component: () => import("../views/generale/diverse/Protocoale.vue"),
    meta: {
      title: 'Protocoale | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Protocoale | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/informatii-generale/cariera", name: "Carieră", component: () => import("../views/generale/diverse/Cariera.vue"),
    meta: {
      title: 'Carieră | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Carieră | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },

  {
    path: "/informatii-generale/conducere", name: "Conducere", component: () => import("../views/generale/organizare/Conducere.vue"),
    meta: {
      title: 'Conducere | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Conducere | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/informatii-generale/consiliu", name: "Consiliu", component: () => import("../views/generale/organizare/Consiliu.vue"),
    meta: {
      title: 'Consiliu | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Consiliu | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/informatii-generale/CEJ", name: "CEJ", component: () => import("../views/generale/organizare/CEJ.vue"),
    meta: {
      title: 'CEJ | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'CEJ | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/informatii-generale/congres", name: "Congres", component: () => import("../views/generale/organizare/Congres.vue"),
    meta: {
      title: 'Congres | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Congres | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/informatii-generale/CSD", name: "CSD", component: () => import("../views/generale/organizare/CSD.vue"),
    meta: {
      title: 'CSD | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'CSD | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/informatii-generale/CAEJ", name: "CAEJ", component: () => import("../views/generale/organizare/CAEJ.vue"),
    meta: {
      title: 'CAEJ | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'CAEJ | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/informatii-generale/CNPPEJ", name: "CNPPEJ", component: () => import("../views/generale/organizare/CNPPEJ.vue"),
    meta: {
      title: 'CNPPEJ | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'CNPPEJ | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },

  // legislatie
  {
    path: "/legislatie/statut", name: "Statut", component: () => import("../views/legislatie/Statut.vue"),
    meta: {
      title: 'Statut | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Statut | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/legislatie/legea188-2000", name: "L. 188/2000", component: () => import("../views/legislatie/Legea188-2000.vue"),
    meta: {
      title: 'L. 188/2000 | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'L. 188/2000 | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/legislatie/legea17-2017", name: "L. 17.2017", component: () => import("../views/legislatie/Legea17-2017.vue"),
    meta: {
      title: 'L. 17.2017 | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'L. 17.2017 | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/legislatie/regulament", name: "Regulament", component: () => import("../views/legislatie/Regulament.vue"),
    meta: {
      title: 'Regulament | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Regulament | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/legislatie/OMJ", name: "OMJ 2550/2006", component: () => import("../views/legislatie/OMJ.vue"),
    meta: {
      title: 'OMJ 2550/2006 | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'OMJ 2550/2006 | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/legislatie/CPCIV", name: "CPCIV", component: () => import("../views/legislatie/CPCIV.vue"),
    meta: {
      title: 'CPCIV | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'CPCIV | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/legislatie/PCSB", name: "Prevenirea și combaterea spălării banilor", component: () => import("../views/legislatie/PCSB.vue"),
    meta: {
      title: 'Prevenirea și combaterea spălării banilor | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Prevenirea și combaterea spălării banilor | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/legislatie/CAJE", name: "Comunicarea actelor judiciare și extrajudiciare", component: () => import("../views/legislatie/CAJE.vue"),
    meta: {
      title: 'Comunicarea actelor judiciare și extrajudiciare | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Comunicarea actelor judiciare și extrajudiciare | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },

  //  harta executorilor
  {
    path: "/harta-executorilor", name: "Harta Executorilor", component: () => import("../views/HartaExecutorilor.vue"),
    meta: {
      title: 'Harta Executorilor | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Harta Executorilor | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },

  // tabloul executorilor
  {
    path: "/tabloul-executorilor", name: "Tabloul Executorilor", component: () => import("../views/TabloulExecutorilor.vue"),
    meta: {
      title: 'Tabloul Executorilor | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Tabloul Executorilor | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },

  // international
  {
    path: "/international", name: "Internațional", component: () => import("../views/international/Despre.vue"),
    meta: {
      title: 'Internațional | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Internațional | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },
  {
    path: "/newsletter-uihj", name: "Newsletter UIHJ", component: () => import("../views/international/Newsletter-uihj.vue"),
    meta: {
      title: 'Newsletter UIHJ | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Newsletter UIHJ | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    }
  },

  { path: "/arhiva-tabloul-executorilor", name: "Arhivă tabloul executorilor ", component: () => import("../views/ArhivaTablou.vue"),
    meta: {
      title: 'Arhivă tabloul executorilor | Uniunea Naţională a Executorilor Judecătoreşti',
      metaTags: [
        {
          name: 'Arhivă tabloul executorilor | Este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.',
          content: 'Uniunea Naţională a Executorilor Judecătoreşti este o organizaţie profesională cu personalitate juridică, formată din toţi executorii judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu organizare, funcţionare şi conducere autonome.'
        }
      ]
    } },


  // dinamic routes
  // { path: "/admitere/examene/:year/:page", name: "show", component: () => import("../views/generale/admitere-in-profesie/show.vue") },
  { path: "/newsletter-uihj/:page", name: "route", component: () => import("../views/international/route.vue") },

  { path: "/test", name: "test", component: () => import("../views/Test.vue") },

  { path: "/:pathName(.*)", name: "NotFound", component: () => import("../views/Home.vue") },
  { path: '/.*', redirect: { name: 'Home' } },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Dacă există o poziție salvată (cum ar fi la întoarcerea cu back button)
    if (savedPosition) {
      return savedPosition
    } else {
      // Scroll la începutul paginii
      return { top: 0 }
    }
  }
});

router.beforeEach((to, from, next) => {
  const meta = to.meta;
  if (meta) {
    useHead({
      title: meta.title,
      meta: meta.metaTags
    });
  }
  next();
});

export default router
