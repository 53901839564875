<template>
  <LoadingBar/>
  <Navbar />
  <router-view />
  <Footer />
  <ScrollToTop />

  <!-- cookie banner -->
  <div class="cookie-banner" v-if="showMessage">
    <small>
      Acest website folosește cookie-uri esențiale. Acestea
      reprezintă
      cookie-urile care sunt necesare pentru ca website-ul să funcționeze în
      mod corespunzător.</small> <br/> <button class="btnCo" @click="myCookies">Am înțeles</button>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import ScrollToTop from './components/ScrollToTop.vue';
import LoadingBar from './components/LoadingBar.vue'


export default {
  name: 'App',
  data() {
    return {
      showMessage: false,
      consent: false,
    }
  },
  components: {
    Navbar, Footer, ScrollToTop,
    LoadingBar
  },
  methods: {
    myCookies() {
      this.$cookies.set("consent", "accepted", "365d");
      this.showMessage = false;
    },
  },
  mounted() {
    if (!this.$cookies.get("consent")) {
      this.consent = false;
      this.showMessage = true;
    } else {
      this.consent = true;
      this.showMessage = false;
    };
    // Apply the saved dark mode setting from localStorage when the app loads
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    if (isDarkMode) {
      document.body.classList.add('dark-theme');
    }
  },
}
</script>


<style scoped>
/* cookie banner */
.cookie-banner {
  position: fixed;
  font-size: .8rem;
  bottom: 0;
  left: 0;
  z-index: 999999999;
  background-color: var(--background-color-secondary);
  color: var(--text-color);
  padding: 20px;
  margin: 10px;
  margin-bottom: 40px;
  height: 160px;
  width: 250px;
  border-radius: 10px;
  border: 1px solid rgba(128, 128, 128, 0.2);
}

.cookie-banner button {
  background-color: green;
  color: white;
  border-radius: 15px;
  border: none;
  margin-top: 10px;
  padding: 5px 10px;
  width: 100%;
}

.cookie-banner button:hover {
  background-color: rgba(0, 128, 0, 0.664);
  transition: .3s;
}
</style>