import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createHead } from '@vueuse/head';
import VueCookies from 'vue-cookies';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './assets/css/main.css'
import './assets/css/animations.css'

const app = createApp(App)
const head = createHead();
app.use(router)
app.use(head);
app.use(VueCookies)
app.mount('#app')
