<template>
  <div id="top-bar" class="top-bar bg-light py-2 d-none d-sm-block">
    <div class="container">
      <div class="row align-items-center">
        <div class="d-flex justify-content-between">
          <ul class="top-info list-unstyled mb-0 d-flex align-items-center">
            <li class="me-4 d-flex align-items-center">
              <i class="bi bi-geo-alt-fill me-1 primary-color"></i>
              <span class="info-text">Str. Cuza Vodă, Nr. 143, Sec. 4, București</span>
            </li>
          </ul>

          <!-- Social Icons -->
          <ul class="list-unstyled mb-0 d-flex justify-content-end align-items-center">
            <li class="me-2">
              <a href="https://www.facebook.com/unejromania" title="Facebook" class="social-icon" target="_blank">
                <i class="bi bi-facebook primary-color"></i>
              </a>
            </li>
            <li class="">
              <a href="https://www.instagram.com/unej_romania" title="Instagram" class="social-icon" target="_blank">
                <i class="bi bi-instagram primary-color"></i>
              </a>
            </li>
            <span class="text-black info-text ms-2">/ UNEJ România </span>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Main Header -->
  <header class="header-one bg-white d-none d-sm-block">
    <div class="container">
      <div class="row no-gutters d-flex align-items-center align-items-stretch">
        <div class="col-md-4 d-flex align-items-center py-2">
          <router-link class="navbar-brand" to="/">
            <Logo />
          </router-link>
        </div>
        <div class="col-lg-8 d-block">
          <div class="row d-flex">
            <div class="col-md d-flex topper align-items-center align-items-stretch py-md-4">
              <div class="icon d-flex justify-content-center align-items-center">
                <i class="bi bi-envelope-fill fs-3 me-2 primary-color"></i> <!-- Iconiță pentru email -->
              </div>
              <div class="text-navbar">
                <span class="title-h6 fw-bold">Email</span>
                <div class="mail-navbar">uniune@executori.ro</div>
              </div>
            </div>
            <div class="col-md d-flex topper align-items-center align-items-stretch py-md-4">
              <div class="icon d-flex justify-content-center align-items-center">
                <i class="bi bi-telephone-fill fs-3 me-2 primary-color"></i> <!-- Iconiță pentru telefon -->
              </div>
              <div class="text-navbar">
                <span class="title-h6 fw-bold">Apelează</span>
                <div class="mail-navbar">(+40)21 320 50 92</div>
              </div>
            </div>
            <div class="col-md topper d-flex align-items-center justify-content-end py-2">
              <p class="mb-0 d-block">
                <router-link to="/contact" class="btn py-2 px-3 blue-btn">
                  <span>Contact</span>
                </router-link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>



  <!-- Navigation Bar -->
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container">
      <Logo class="d-sm-none" />
      <div class="top-bar-right d-sm-none">
        <div class="social">
          <a href="tel:0213205092"><i class="bi bi-telephone-fill"></i></a>
          <a href="mailto:uniune@executori.ro"><i class="bi bi-envelope-fill"></i></a>
          <a class="navbar-toggler ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <i class="bi bi-list"></i>
          </a>
        </div>
      </div>
      <a class="navbar-toggler ms-auto d-none d-sm-block d-lg-none desktop-menu-btn" type="button"
        data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="fs-6 me-2">MENIU</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" stroke="currentColor"
          stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-menu"
          viewBox="0 0 24 24">
          <line x1="3" y1="12" x2="21" y2="12" />
          <line x1="3" y1="6" x2="21" y2="6" />
          <line x1="3" y1="18" x2="21" y2="18" />
        </svg>
      </a>
      <div class="collapse navbar-collapse" id="navbarNav" :class="!navOpen4Visible ? 'collapse' : ''">
        <ul class="navbar-nav me-auto">
          <li class="nav-item dropdown" @click="toggleDropdown" @mouseleave="closeDropdown">
            <button class="nav-link dropdown-toggle" id="navbarDropdown" role="button" aria-expanded="false">
              Informații Generale
              <i class="bi bi-caret-down-fill"></i>
            </button>
            <ul class="dropdown-menu info" :class="{ show: isDropdownOpen }" aria-labelledby="navbarDropdown">
              <li class="dropdown-submenu" @click.stop="toggleSubmenu(1)">
                <button class="dropdown-item dropdown-toggle">Prezentare<i
                    class="bi bi-caret-right-fill float-end"></i></button>
                <ul class="dropdown-menu" :class="{ show: isSubmenuOpen[1] }"
                  @click="navOpen4Visible = !navOpen4Visible">
                  <li><router-link class="dropdown-item" to="/informatii-generale/despre">Despre</router-link></li>
                  <li><router-link class="dropdown-item" to="/informatii-generale/atributii-executor">Atribuții
                      executor</router-link></li>
                  <li><router-link class="dropdown-item border-0"
                      to="/informatii-generale/despre-executarea-silita">Despre executarea silită</router-link></li>
                </ul>
              </li>
              <li class="dropdown-submenu" @click.stop="toggleSubmenu(2)">
                <button class="dropdown-item dropdown-toggle">Organizare<i
                    class="bi bi-caret-right-fill float-end"></i></button>
                <ul class="dropdown-menu" :class="{ show: isSubmenuOpen[2] }"
                  @click="navOpen4Visible = !navOpen4Visible">
                  <li><router-link class="dropdown-item" to="/informatii-generale/conducere">Conducere
                      UNEJ</router-link></li>
                  <li><router-link class="dropdown-item" to="/informatii-generale/consiliu">Consiliu</router-link></li>
                  <li><router-link class="dropdown-item" to="/informatii-generale/congres">Congres</router-link></li>
                  <li><router-link class="dropdown-item" to="/informatii-generale/CNPPEJ">CNPPEJ</router-link></li>
                  <li><router-link class="dropdown-item" to="/informatii-generale/CSD">CSD</router-link></li>
                  <li><router-link class="dropdown-item" to="/informatii-generale/CAEJ">CAEJ</router-link></li>
                  <li><router-link class="dropdown-item border-0" to="/informatii-generale/CEJ">CEJ</router-link></li>
                </ul>
              </li>
              <li class="dropdown-submenu" @click.stop="toggleSubmenu(3)">
                <button class="dropdown-item dropdown-toggle">Admitere in profesie<i
                    class="bi bi-caret-right-fill float-end"></i></button>
                <ul class="dropdown-menu" :class="{ show: isSubmenuOpen[3] }"
                  @click="navOpen4Visible = !navOpen4Visible">
                  <li><router-link class="dropdown-item" to="/informatii-generale/admitere">Cum să devin
                      executor</router-link></li>
                  <li><router-link class="dropdown-item" to="/informatii-generale/examene">Examene și
                      concursuri</router-link></li>
                  <li><router-link class="dropdown-item border-0" to="/informatii-generale/arhiva-examene">Arhivă
                      examene și concursuri</router-link></li>
                </ul>
              </li>
              <li class="dropdown-submenu" @click.stop="toggleSubmenu(4)">
                <button class="dropdown-item dropdown-toggle">Proiecte<i
                    class="bi bi-caret-right-fill float-end"></i></button>
                <ul class="dropdown-menu" :class="{ show: isSubmenuOpen[4] }"
                  @click="navOpen4Visible = !navOpen4Visible">
                  <li><router-link class="dropdown-item" to="/poca2022-2023">POCA 2022-2023</router-link></li>
                  <li><router-link class="dropdown-item border-0" to="/poca2018-2021">POCA 2018-2021</router-link></li>
                </ul>
              </li>
              <li class="dropdown-submenu" @click.stop="toggleSubmenu(5)">
                <button class="dropdown-item dropdown-toggle">Biroul de presă<i
                    class="bi bi-caret-right-fill float-end"></i></button>
                <ul class="dropdown-menu" :class="{ show: isSubmenuOpen[5] }"
                  @click="navOpen4Visible = !navOpen4Visible">
                  <li><router-link class="dropdown-item" to="/informatii-generale/biroul-de-presa">Biroul de
                      presă</router-link></li>
                  <li><router-link class="dropdown-item border-0"
                      to="/informatii-generale/buletinul-informativ">Buletinul informativ</router-link></li>
                </ul>
              </li>
              <li class="dropdown-submenu" @click.stop="toggleSubmenu(6)">
                <button class="dropdown-item border-0 dropdown-toggle">Diverse<i
                    class="bi bi-caret-right-fill float-end"></i></button>
                <ul class="dropdown-menu" :class="{ show: isSubmenuOpen[6] }"
                  @click="navOpen4Visible = !navOpen4Visible">
                  <li><router-link class="dropdown-item" to="/informatii-generale/protocoale">Protocoale</router-link>
                  </li>
                  <li><router-link class="dropdown-item border-0" to="/informatii-generale/cariera">Carieră (Anunțuri
                      angajare)</router-link></li>
                </ul>
              </li>
            </ul>
          </li>

          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Legislație
              <i class="bi bi-caret-down-fill"></i>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown2" @click="navOpen4Visible = !navOpen4Visible">
              <li><router-link class="dropdown-item" to="/legislatie/legea188-2000">L. 188/2000</router-link></li>
              <li><router-link class="dropdown-item" to="/legislatie/legea17-2017">L. 17.2017</router-link></li>
              <li><router-link class="dropdown-item" to="/legislatie/statut">Statut</router-link></li>
              <li><router-link class="dropdown-item" to="/legislatie/regulament">Regulament</router-link></li>
              <li><router-link class="dropdown-item" to="/legislatie/OMJ">OMJ 2550/2006</router-link></li>
              <li><router-link class="dropdown-item" to="/legislatie/CPCIV">CPCIV</router-link></li>
              <li><router-link class="dropdown-item" to="/legislatie/PCSB">Prevenirea și combaterea spălării
                  banilor</router-link></li>
              <li><router-link class="dropdown-item border-0" to="/legislatie/CAJE">Comunicarea actelor judiciare și
                  extrajudiciare</router-link></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown3" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              Internațional
              <i class="bi bi-caret-down-fill"></i>
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown3" @click="navOpen4Visible = !navOpen4Visible">
              <li><a class="dropdown-item" href="https://uihj.com" target="_blank">UIHJ.com</a></li>
              <li><router-link class="dropdown-item" to="/newsletter-uihj">Newsletter UIHJ</router-link></li>
              <li><a class="dropdown-item" href="https://uehj.eu" target="_blank">UEHJ.eu</a></li>
              <li><router-link class="dropdown-item border-0" to="/international">Despre</router-link></li>
            </ul>
          </li>
          <li class="nav-item" @click="navOpen4Visible = !navOpen4Visible">
            <router-link to="/tabloul-executorilor" class="nav-link">Tabloul executorilor
              <i class="bi bi-caret-right-fill icon-arrow-right display-none"></i>
            </router-link>
          </li>
          <li class="nav-item" @click="navOpen4Visible = !navOpen4Visible">
            <router-link to="/harta-executorilor" class="nav-link">Harta executorilor
              <i class="bi bi-caret-right-fill icon-arrow-right display-none"></i>
            </router-link>
          </li>
          <li class="nav-item" @click="navOpen4Visible = !navOpen4Visible">
            <a href="https://unejr.ro" class="nav-link" target="_blank">REPES
              <i class="bi bi-caret-right-fill icon-arrow-right display-none"></i>
            </a>
          </li>
          <li class="nav-item" @click="navOpen4Visible = !navOpen4Visible">
            <a href="https://silex.executori.ro" class="nav-link" target="_blank">SiLex
              <i class="bi bi-caret-right-fill icon-arrow-right display-none"></i>
            </a>
          </li>
          <!-- Add more dropdowns as needed -->
          <div class="mobile button-container mt-2">
            <div class="my-row-router">
              <a class="col btn blue-btn square-btn" href="https://www.facebook.com/profile.php?id=61560413607750"
                target="_blank"><i class="bi bi-facebook px-2"></i></a>
              <a class="col btn blue-btn square-btn" href="https://www.instagram.com/unej_romania" target="_blank"><i
                  class="bi bi-instagram px-2"></i></a>
            </div>
            <div class="my-row-router">

              <router-link class="col btn blue-btn square-btn" to="/contact"
                @click="navOpen4Visible = !navOpen4Visible">Contact</router-link>
              <a class="col btn blue-btn square-btn" href="https://executori.ro/membri/" target="_blank">
                Membri</a>
            </div>

            <div class="text-white"><i class="bi bi-geo-alt-fill px-1"></i>Str. Cuza Voda, Nr. 143, Sec.
              4, Bucuresti
            </div>
            <div class="text-white"><i class="bi bi-telephone-fill px-1"></i>021 320 50 92</div>
            <div class="text-white"><i class="bi bi-envelope-fill px-1"></i>uniune@executori.ro</div>
          </div>
        </ul>
      </div>

      <li class="nav-item dropdown d-flex d-none d-lg-block">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-bs-toggle="dropdown"
          aria-expanded="false">
          <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-user">
            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
          Contul meu
          <i class="bi bi-caret-down-fill"></i>
        </a>
        <ul class="dropdown-menu py-4 logare" aria-labelledby="navbarDropdown2">
          <span class="text-muted small" style="margin-left: 25px;">Acces membri,</span>
          <li><a class="dropdown-item border-0" href="https://executori.ro/membri" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" stroke="currentColor"
                stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-unlock mb-1"
                viewBox="0 0 24 24">
                <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
                <path d="M7 11V7a5 5 0 019.9-1" />
              </svg>
              Autentificare
            </a></li>
        </ul>
      </li>
    </div>
  </nav>
</template>

<script>
import Logo from './Logo.vue'

export default {
  components: {
    Logo
  },
  data() {
    return {
      navOpen4Visible: false,
      isDropdownOpen: false,
      isSubmenuOpen: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false
      }
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    closeDropdown() {
      this.isDropdownOpen = false;
      this.isSubmenuOpen = { 1: false, 2: false, 3: false, 4: false, 5: false, 6: false }; // Închide toate submeniurile
    },
    toggleSubmenu(index) {
      // Închide submeniurile deschise
      Object.keys(this.isSubmenuOpen).forEach((key) => {
        if (key != index) {
          this.isSubmenuOpen[key] = false; // Închide celelalte submeniuri
        }
      });
      this.isSubmenuOpen[index] = !this.isSubmenuOpen[index]; // Comută starea submeniului curent
    }
  }
};


</script>

<style scoped>
.icon-arrow-right::before {
  float: right;
  margin-right: 20px;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.my-row-router {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 20px;
}

.square-btn {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0 10px;
  cursor: pointer;
}

.nav-link {
  border-radius: 15px;
}

.nav-link:hover {
  background-color: #394047;
  border-radius: 15px;
}

/* Stil pentru meniul colapsat */
.navbar-collapse {
  position: fixed;
  top: 65px;
  /* Aceasta este înălțimea implicită a navbar-ului Bootstrap */
  left: 0;
  width: 100%;
  height: calc(100% - 56px);
  /* Ajustează înălțimea pentru a evita suprapunerea cu navbar-ul */
  background-color: #212529;
  /* Culoarea de fundal a navbar-ului */
  /* z-index: 1000000000; */
  /* Asigură-te că meniul este deasupra altor elemente */
  overflow-y: auto;
  /* Adaugă scroll dacă conținutul depășește înălțimea ecranului */
  display: none;
  /* Ascunde meniul în mod implicit */
}

/* Stil pentru a face meniul vizibil pe ecranele mici */
@media (max-width: 576px) {

  /* Ajustează dimensiunea după preferințe */
  .navbar-collapse {
    padding: 20px;
    /* Arată meniul doar pe ecranele mici */
  }

  .nav-link i {
    position: absolute;
    right: 0;
  }
}

@media (min-width: 992px) {

  /* Ajustează dimensiunea după preferințe */
  .navbar-collapse {
    display: block;
    /* Arată meniul doar pe ecranele mici */
  }

}

/* Stil pentru a face meniul vizibil */
.navbar-collapse.show {
  display: block;
  /* Asigură-te că este afișat când este activat */
}

/* Meniul pe ecrane mari */
@media (min-width: 576px) {

  /* Ajustează dimensiunea după preferințe */
  .navbar-collapse {
    position: static;
    /* Schimbă comportamentul pentru ecranele mari */
    height: auto;
    /* Permite înălțimea automată */
    background-color: transparent;
    /* Sau orice culoare dorită */
    overflow: visible;
    /* Face conținutul vizibil */
  }
}

@media only screen and (min-width : 576px) {
  .mobile {
    display: none;
  }

  .display-none {
    display: none;
  }
}

/*  */
.text-navbar .title-h6 {
  position: relative;
  /* top: 5px;  */
}

.text-navbar .mail-navbar {
  position: relative;
  top: -5px;
  /* Ajustează valoarea pentru a controla distanța */
}

.dropdown-toggle::after {
  display: none !important;
  /* Ascunde săgeata implicită */
}

.dropdown .dropdown-menu {
  border: none;
  border-radius: 15px;
  box-shadow: none;
  padding: 10px;
}

.info {
  min-width: 250px;

}

.dropdown .dropdown-menu .dropdown-item {
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown .dropdown-menu .dropdown-item:hover {
  background-color: var(--primary-color);
  color: #f5f5f5;
}

.border-none {
  border: none;
}

/* Stilizare pentru submeniuri */
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  left: 106%;
  top: 0;
  margin-top: 0;
}

.desktop-menu-btn {
  text-decoration: none;
}

.top-bar,
nav {
  font-weight: 500;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar i {
  font-size: .6rem;
}

.top-bar-right i {
  font-size: 1.5rem;
}

.mobile i {
  font-size: 1.5rem;
}

.nav-item .nav-link {
  color: #cacaca !important;
}

.top-bar {
  background-color: #f8f9fa;
  padding: 10px 0;
}

.info-text {
  font-size: 14px;
}

.top-info-box {
  list-style: none;
}

.info-box-title {
  font-weight: bold;
}

.top-bar-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.top-bar-right .social {
  display: flex;
  height: 50px;
  font-size: 0;
  justify-content: flex-end;
}

.top-bar-right .social a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 57px;
  height: 100%;
  border-radius: 0px;
  font-size: 22px;
  border: none;
  color: white;
}

.top-bar-right .social a:hover {
  background-color: var(--primary-color);
  color: #fff;
}

.top-bar-right .social a {
  border-left: 1px solid rgba(219, 219, 219, 0.123);
}

.top-bar-right .social a:first-child {
  border-left: none
}
</style>
