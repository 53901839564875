<template>
  <!-- Modal -->
  <div class="modal fade" id="JuridiceModal" tabindex="-1" aria-labelledby="JuridiceModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="JuridiceModalLabel"><img class="logo-juridice"
              src="../assets/img/juridice.png"> Știri Juridice.Ro
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body" v-for="(item, index) in rss" :key="index">
          <i class="bi bi-rss-fill px-1"></i><a class="link-modal" :href="item.link">{{ item.title }}</a>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal"><b>închide</b></button>
        </div>
      </div>
    </div>
  </div>


  <div class="popup">
    <button class="vertical-btn" data-bs-toggle="modal" data-bs-target="#JuridiceModal">Juridice.Ro</button>
  </div>


  <!-- <div id="hide-uj">
    <button id="btn-uj" @click="hideImage()"><i class="bi bi-x"></i></button>
    <a href="https://universuljuridic.ro"><img id="uj-image" src="../assets/my-img/uj_mobile2.jpeg"
        alt="universuljuridic" /></a>
  </div> -->


  <div class="marquee-title"><img src="../assets/img/juridice.png" alt="juridice ro"
      style="max-width: 25px; margin-bottom: 5px;"><a class="juridice-link px-1"
      href="https://juridice.ro"><b>Juridice.Ro</b></a></div>
  <div class="marquee">
    <div class="marquee__content">
      <div class="list-inline" v-for="(item, index) in rss" :key="index">
        <div><a class="link-li" :href="item.link">{{ item.title }} <span class="icon-span">&#8718;</span> </a></div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.modal-content {
  background-color: var(--background-color);
  color: var(--text-color);
}

#btn-uj {
  display: none;
  position: fixed;
  bottom: 55px;
  right: 240px;
  background-color: #e62828;
  color: white;
  font-weight: bold;
  padding: 1px;
  border: 2px solid transparent;
  width: 30px;
  border-radius: 100%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.952);
  z-index: 100;

  &:hover {
    border: 2px solid white;
    transition: .2s;
  }
}

#uj-image {
  position: fixed;
  bottom: 30px;
  right: 0;
  width: 270px;
  border: 2px solid transparent;
  border-radius: 20px;
  z-index: 99;

  &:hover {
    border: 2px solid #296CA6;
    transition: .2s;
  }
}

#hide-uj:hover img {
  margin: 0
}

#hide-uj:hover button {
  display: inline-block;
}

.logo-juridice {
  max-width: 35px;
}

.icon-span {
  margin-left: 5px;
  margin-right: 10px;
}

// .btn-modal-jur {
//   background-color: #e62828;
//   color: white;
//   border: none;
//   border-radius: 10px;
//   font-size: 15px;
//   padding: 5px;
//   box-shadow: 1px 1px 5px #222;
// }

// .btn-modal-jur:hover {
//   background-color: #e62828cb;
// }

.link-modal {
  text-decoration: none;
  color: var(--text-color);
}

.link-modal:hover {
  color: #296CA6;
}

.link-li {
  text-decoration: none;
  color: white;
}

.link-li:hover {
  text-decoration: none;
  color: var(--primary-color);
}

.juridice-link {
  text-decoration: none;
  color: #333;
}

.juridice-link:hover {
  color: #e62828;
}

.marquee {
  width: 100%;
  height: 30px;
  white-space: nowrap;
  text-indent: 0px;
  background-color: #14171a;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;



  &:before,
  &:after {
    top: 0;
    height: 30px;
    content: "";
    z-index: 1;
  }

  &:before {
    left: 0;
    background: linear-gradient(to right, white 5%, transparent 100%);
  }

  &:after {
    right: 0;
    background: linear-gradient(to left, white 5%, transparent 100%);
  }
}

.marquee-title {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 137px;
  // background: #e62828;
  border-top: 1px solid black;
  background: white;
  z-index: 9;
  padding: 3px 10px;
  height: 30px;

}

.marquee-title:after {
  position: absolute;
  content: "";
  right: -40px;
  // border-left: 19px solid #e62828;
  border-left: 20px solid white;

  border-top: 30px solid transparent;
  border-right: 20px solid transparent;
  top: 0px
}

.marquee__content {
  width: 550% !important;
  display: flex;
  line-height: 30px;
  animation: marquee 80s linear infinite forwards;

  &:hover {
    animation-play-state: paused;
  }
}

.list-inline {
  display: flex;
  justify-content: space-around;
  width: 33.33%;

  /* reset list */
  list-style: none;
  padding: 0;
  margin: 0;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-66.6%);
  }
}

@media only screen and (max-width: 800px) {
  .marquee {
    display: none
  }

  .marquee-title {
    display: none
  }

  #uj-image {
    bottom: 0;
  }

  #btn-uj {
    bottom: 20px;
  }
}

@media only screen and (min-width: 800px) {
  .popup {
    display: none
  }
}

// button vertical
.vertical-btn {
  background: #e62828;
  border-radius: 0;
  border: none;
  color: white;
  display: inline-block;
  font-size: 0.9rem;
  height: 30px;
  line-height: 30px;
  position: fixed;
  right: 0;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transform-origin: bottom right;
  width: 120px;
  top: 400px;
  z-index: 100;

  &:hover {
    background-color: #e62828c5;
  }
}
</style>

<script>
import axios from 'axios';
import xml2js from 'xml2js';

export default {
  data() {
    return {
      rss: []
    }
  },
  mounted() {
    axios.get('https://www.executori.ro/api/public/getrss', {
      headers: {
        'Content-Type': 'text/xml',
        'Accept': 'aplication/xml'
      }
    })
      .then(response => {
        this.parseXML(response.data)
          .then((data) => {
            this.rss = data;
            // console.log(this.rss)
          });
      })

  },
  methods: {
    parseXML(data) {
      return new Promise(resolve => {
        var k = "";
        var arr = [],
          parser = new xml2js.Parser(
            {
              trim: true,
              explicitArray: true
            });
        parser.parseString(data, function (err, result) {
          var obj = result.rss.channel[0];
          for (k in obj.item) {
            var item = obj.item[k];
            arr.push({
              title: item.title[0],
              link: item.link,

            });
          }
          resolve(arr);
        });
      });
    },
    hideImage() {
      document.getElementById("hide-uj").style.display = "none";
    }
  },
}
</script>