<template>
    <div class="container-fluid footer py-5 wow fadeIn bg-dark">
        <div class="container py-2">
            <div class="row">
                <div class="col">
                    <div class="mb-5">
                        <div class="row g-4">
                            <div class="col-md-6 col-lg-6 col-xl-6">
                                <div class="footer-item">
                                    <router-link to="/"
                                        class="d-flex align-items-center text-decoration-none text-white">
                                        <img src="/logo.png" alt="executori" style="height: 60px;">
                                        <h3 class="px-2 fs-1">executori.ro</h3>
                                    </router-link>
                                    <p class="text-white-50 mt-4">Uniunea Naţională a Executorilor Judecătoreşti este o
                                        organizaţie profesională cu personalitate juridică, formată din toţi executorii
                                        judecătoreşti din România numiţi de ministrul justiţiei în condiţiile legii, cu
                                        organizare, funcţionare şi conducere autonome.</p>
                                    <div class="footer-btn d-flex">
                                        <a href="https://www.facebook.com/unejromania" target="_blank">
                                            <div class="rounded-circle bg-secondary text-white rounded me-4"
                                                style="height: 60px; width: 60px;display: flex; justify-content: center;align-items: center;">
                                                <i class="bi bi-facebook fs-3"></i>
                                            </div>
                                        </a>

                                        <a href="https://www.instagram.com/unej_romania" target="_blank">
                                            <div class="rounded-circle bg-secondary text-white rounded me-4"
                                                style="height: 60px; width: 60px;display: flex; justify-content: center;align-items: center;">
                                                <i class="bi bi-instagram fs-3"></i>
                                            </div>
                                        </a>

                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-lg-6 col-xl-3">
                                <div class="footer-item d-flex flex-column">
                                    <h4 class="text-white mb-5 mt-2">Acces membri</h4>
                                    <a href="https://executori.ro/membri" target="_blank"><i
                                            class="bi bi-unlock me-2"></i>Autentificare</a>
                                </div>
                            </div>
                            <div class="col-md-6 col-lg-6 col-xl-3">
                                <div class="footer-item d-flex flex-column">
                                    <h4 class="text-white mb-5 mt-2">Linkuri rapide</h4>
                                    <router-link to="/contact"><i
                                            class="bi bi-arrow-return-right me-2"></i>Contact</router-link>
                                    <a href="https://silex.executori.ro"><i
                                            class="bi bi-arrow-return-right me-2"></i>SiLex</a>
                                    <a href="https://unejr.ro"><i class="bi bi-arrow-return-right me-2"></i>REPES</a>
                                    <router-link to="/tabloul-executorilor"><i
                                            class="bi bi-arrow-return-right me-2"></i>Tabloul
                                        Executorilor</router-link>
                                    <router-link to="/harta-executorilor"><i
                                            class="bi bi-arrow-return-right me-2"></i>Harta
                                        Executorilor</router-link>
                                    <router-link to="/informatii-generale/conducere"><i class="bi bi-arrow-return-right me-2"></i>Conducere
                                        UNEJ</router-link>
                                        <router-link to="/GDPR"><i class="bi bi-arrow-return-right me-2"></i>Consimțământ GDPR</router-link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pt-5" style="border-top: 1px solid rgba(255, 255, 255, 0.08);">
                        <div class="row g-0">
                            <div class="col-12">
                                <div class="row g-4">
                                    <div class="col-lg-6 col-xl-4">
                                        <div class="d-flex">
                                            <div class="rounded-circle primary-bg text-white rounded me-4"
                                                style="height: 60px; width: 60px;display: flex; justify-content: center;align-items: center;">
                                                <i class="bi bi-geo-alt-fill fs-3"></i>
                                            </div>
                                            <div>
                                                <h4 class="text-white">Adresa</h4>
                                                <p class="mb-0 text-white-50">Str. Cuza Vodă, Nr. 143, Sec. 4, București
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-xl-4">
                                        <div class="d-flex">
                                            <div class="rounded-circle primary-bg text-white rounded me-4"
                                                style="height: 60px; width: 60px;display: flex; justify-content: center;align-items: center;">
                                                <i class="bi bi-envelope-fill fs-3"></i>
                                            </div>
                                            <div>
                                                <h4 class="text-white">Email</h4>
                                                <p class="mb-0 text-white-50">uniune@executori.ro</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-xl-4">
                                        <div class="d-flex">
                                            <div class="rounded-circle primary-bg text-white rounded me-4"
                                                style="height: 60px; width: 60px;display: flex; justify-content: center;align-items: center;">
                                                <i class="bi bi-telephone-fill fs-3"></i>
                                            </div>
                                            <div>
                                                <h4 class="text-white">Apelează</h4>
                                                <p class="mb-0 text-white-50">(+40)21 320 50 92</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-start text-white-50 mt-5" style="border-top: 1px solid rgba(255, 255, 255, 0.08); padding-top: 30px;">
                {{ new Date().getFullYear() }} &#169; Uniunea Naţională a Executorilor
                Judecătoreşti
            </div>
        </div>

    </div>
</template>


<style>
.footer-item a {
    text-decoration: none;
    color: #6c757d;
}

.footer-item a:hover {
    color: var(--primary-color);
    transition: .3s;
}

.footer-btn a .rounded-circle:hover {
    background-color: var(--primary-color) !important;
}
</style>
