<template>
    <div class="gallery">
        <!-- Primele două imagini afișate -->
        <div v-for="(image, index) in displayedImages" :key="index" class="image-container" @click="openOverlay(index)">
            <img :src="image" :alt="'Image ' + (index + 1)" class="gallery-image" />
        </div>

        <!-- Placeholder pentru imaginile rămase cu afișarea imaginii a treia -->
        <div v-if="remainingImagesCount > 0" class="image-container remaining-images" @click="openFullGallery">
            <img :src="gallery[2]" alt="Remaining Images" class="gallery-image remaining-image" />
            <span class="remaining-count">+{{ remainingImagesCount }}</span>
        </div>

        <!-- Secțiunea Overlay -->
        <div v-if="isOverlayOpen" class="overlay">
            <span class="close-btn" @click="closeOverlay"><i class="bi bi-x-lg"></i></span>
            <button class="nav-btn prev-btn" @click="prevImage" :disabled="currentImage <= 0">
                <i class="bi bi-chevron-compact-left"></i>
            </button>
            <img :src="gallery[currentImage]" class="overlay-image" />
            <button class="nav-btn next-btn" @click="nextImage" :disabled="currentImage >= gallery.length - 1">
                <i class="bi bi-chevron-compact-right"></i>
            </button>
        </div>

        <!-- Full gallery overlay popup with close button -->
        <div v-if="isFullGalleryOpen" class="full-gallery-popup">
            <span class="close-btn" @click="closeFullGallery">
                <i class="bi bi-x-lg"></i>
            </span>
            <div class="full-gallery row g-1 mt-5">
                <div v-for="(image, index) in gallery" :key="index" class="col-6 col-md-4" @click="openOverlay(index)">
                    <img :src="image" :alt="'Image ' + (index + 1)" class="full-gallery-image w-100" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        gallery: {
            type: Array,
            required: true
        },
        galleryId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            currentImage: null,
            isOverlayOpen: false,
            isFullGalleryOpen: false
        };
    },
    computed: {
        displayedImages() {
            return this.gallery.slice(0, 2);
        },
        remainingImagesCount() {
            return this.gallery.length > 2 ? this.gallery.length - 2 : 0;
        }
    },
    methods: {
        openOverlay(index) {
            this.currentImage = index;
            this.isOverlayOpen = true;
        },
        closeOverlay() {
            this.isOverlayOpen = false;
        },
        prevImage() {
            if (this.currentImage > 0) {
                this.currentImage--;
            }
        },
        nextImage() {
            if (this.currentImage < this.gallery.length - 1) {
                this.currentImage++;
            }
        },
        openFullGallery() {
            this.isFullGalleryOpen = true;
        },
        closeFullGallery() {
            this.isFullGalleryOpen = false;
        }
    }
};
</script>

<style scoped>
.gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    position: relative;
}

.image-container {
    position: relative;
    cursor: pointer;
}

.gallery-image {
    width: 150px;
    height: 100px;
    object-fit: cover;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000001;
}

.overlay-image {
    max-width: 80%;
    max-height: 80%;
}

.close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    cursor: pointer;
    font-size: 30px;
}

.remaining-images {
    position: relative;
    width: 150px;
    height: 100px;
    background-color: #000000;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.remaining-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.6;
}

.remaining-count {
    position: absolute;
    font-size: 40px;
    color: white;
}

.nav-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border: none;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    cursor: pointer;
}

.prev-btn {
    left: 10px;
}

.next-btn {
    right: 10px;
}

.nav-btn:disabled {
    background-color: #666;
    cursor: not-allowed;
}

/* .full-gallery-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 800px;
    height: 80%;
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1000000;
    padding: 20px;
    box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.2);
    border-radius: 30px;
    overflow-y: auto;
} */
.full-gallery-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    /* Background overlay */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000000;
    padding: 20px;
    overflow-y: auto;
}

.full-gallery {
    width: 90%;
    max-width: 800px;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

/* Close button for the full gallery popup */
.full-gallery-popup .close-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    font-size: 30px;
    cursor: pointer;
    z-index: 1000001;
}

.full-gallery-image {
    height: 150px;
    object-fit: cover;
    cursor: pointer;
}

@media (max-width: 768px) {

    .gallery-image,
    .remaining-images {
        width: 100px;
        height: 70px;
    }
}

@media (max-width: 480px) {

    .gallery-image,
    .remaining-images {
        width: 100px;
        height: 70px;
    }
}
</style>
