<template>
    <router-link to="/" class="my-logo-unej-2024">
      <div class="rotunda"><img src="/logo.png" alt=""></div>
      <div class="text-logo">
        <h1>UNEJ</h1>
        <div>România</div>
      </div>
    </router-link>
  </template>
  
  <style scoped>
  .my-logo-unej-2024 {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    text-decoration: none;
  }
  
  .rotunda img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
    -webkit-object-fit: cover; /* Prefix pentru compatibilitate */
    object-fit: cover; /* Proprietate standard */
  }
  
  .text-logo {
    text-align: left;
  }
  
  .text-logo h1 {
    margin: 0;
    font-size: 20px;
    color: var(--text-color, #444); /* Valoare de rezervă pentru compatibilitate */
    line-height: 1;
    font-weight: 900;
  }
  
  .text-logo div {
    margin: 0;
    font-size: 14px;
    /* font-weight: 300; */
    color: var(--text-color, #666); /* Valoare de rezervă pentru compatibilitate */
    line-height: 1.2;
  }
  
  /* Media query pentru display-uri mai mici de 576px */
  @media (max-width: 576px) {
    .text-logo h1,
    .text-logo div {
      color: #fff; /* Schimbă culoarea textului la alb */
    }
  }
  </style>
  